<template>
  <div class="container">
    <div class="g1">
        <img class="image" :src="require(`@/assets/info.webp`)" :alt="'Image of skiwiarts character and about me'">
        <div class="icons-zone">
          <div class="icon">
            <IconHover :image="'twitter'" :link="'https://x.com/skiwiarts'"/>
          </div>
          <div class="icon">
            <IconHover :image="'bluesky'" :link="'https://bsky.app/profile/skiwiarts.bsky.social'"/>
          </div>
          <div class="icon">
            <IconHover :image="'furaffinity'" :link="'https://www.furaffinity.net/user/skiwiarts'"/>
          </div>
          <div class="icon">
            <IconHover :image="'telegram'" :link="'https://t.me/skiwiarts'"/>
          </div>
          <div class="icon">
            <IconHover :image="'discord'" :link="'https://discord.com/users/996306975421898824'"/>
          </div>
          
        </div>
        <div class="old-zone">
          <img
            v-for="(image, index) in images"
            :key="index"
            class="oldart"
            :class="{'active-image': currentImage === index}" 
            :src="require(`@/assets/${image.filename}.webp`)"
            :alt="image.alt"
          >
        </div>
    </div>
    <div class="g2">
      <img class="image" :src="require(`@/assets/openings.webp`)" :alt="'openings image example'">
    </div>
    <div class="g3">
        <img class="image background" :src="require(`@/assets/art_process_bg.webp`)" :alt="'art process background'">
        <img class="image stickers" :src="require(`@/assets/art_process.webp`)" :alt="'art process stickers example'">
    </div>

  </div>
</template>

<script>
import IconHover from '@/components/IconHover.vue'

export default {
  name: 'ImageInfo',

   components: {
        IconHover
    },

  data() {
    return {
      currentImage: 0,
      intervalId: null,
      images: [
        { filename: 'old_arts1', alt: 'old art examples 1'},
        { filename: 'old_arts2', alt: 'old art examples 2'},
        { filename: 'old_arts3', alt: 'old art examples 3'},
        { filename: 'old_arts4', alt: 'old art examples 4'},
        { filename: 'old_arts5', alt: 'old art examples 5'},
      ],
    }
  },

  props: {

  },

  mounted() {
    this.intervalId = setInterval(this.SwitchImage, 3000);
  },

  beforeUnmount() {
    clearInterval(this.intervalId);
  },

  methods: {
    SwitchImage() {
      this.currentImage++;

      if (this.currentImage >= this.images.length)
      {
        this.currentImage = 0;
      }
    }
  },
}
</script>


<style scoped>

.g1 {
  position: relative;
  margin-bottom: 2%;
}

.icons-zone {
  position: absolute;

  width: 46.2962%;
  height: 7.2381%;
  top: 24.6259%;
  left: 42.9629%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 20%;
}


.old-zone {
  position: absolute;
  width: 29.2592%;
  height: 56.8944%;
  top: 34.4520%;
  left: 61.7283%;
}

.oldart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.g3 {
 position: relative;
  margin-bottom: 10%;
}

.stickers {
  position: relative;
  
}

.background {
  position: absolute;
  top: 25%;
}




.active-image {
  opacity: 1;
  z-index: 5;
}




</style>
