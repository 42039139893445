<template>
  <div class="container">
    <p class="title display-text">Terms of Service</p>
    <a class="link" href="https://docs.google.com/document/d/1I-6uX7FB2sP80BAMHKQNDG29a_7-2S79hO4fvrttp4M/edit?usp=sharing">Link to word document format in case the text below is unreadable on your device</a>
  </div>
</template>

<script>



export default {
  name: 'TosMenu',

   components: {
        
    },

  data() {
    return {
      
    }
  },

  props: {

  },

  methods: {

  },
}
</script>


<style scoped>

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
  color: #515aa9;
}

.link {
  font-size: 0.8em;
}


</style>
