<template>
  <div class="cm-main">
    <div class="cm-tabs">
      <p class="cm-text display-text" :class="{'active-tab': activeMenu === 1}" @click="SetActiveMenu(1)">styles</p>
      <p class="cm-text display-text" :class="{'active-tab': activeMenu === 2}" @click="SetActiveMenu(2)">sheets</p>
      <p class="cm-text display-text" :class="{'active-tab': activeMenu === 3}" @click="SetActiveMenu(3)">stickers</p>
    </div>
    <div class="cm-menus">
      <ContentStyles v-if="activeMenu === 1"/>
      <ContentSheets v-if="activeMenu === 2"/>
      <ContentStickers v-if="activeMenu === 3"/>
    </div>
  </div>
</template>

<script>
import ContentStyles from '@/components/ContentStyles.vue'
import ContentSheets from '@/components/ContentSheets.vue'
import ContentStickers from '@/components/ContentStickers.vue'

export default {
  name: 'ContentMenu',

   components: {
        ContentStyles, ContentSheets, ContentStickers
    },

  data() {
    return {
      activeMenu: 1
    }
  },

  props: {

  },

  methods: {
    SetActiveMenu(num) {
      this.activeMenu = num
    }
  },
}
</script>


<style scoped>

.cm-main, .cm-tabs, .cm-menus {
  width: 100%;
  display: flex;
}

.cm-main {
  flex-direction: column;
  justify-content: flex-start;
}

.cm-tabs {
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 2%;
}

.cm-menus {
  flex-direction: column;
  justify-content: flex-start;
}

.cm-text {
  font-weight: 300;
  color: #5f69c5;
  transition: 0.4s ease-in-out;
  overflow: hidden;
  transform: scale(0.95);
  border-bottom: 2px solid transparent;
}

.cm-text:hover {
  color: #b75371;
  cursor: pointer;
  transform: scale(1.0);
}

.active-tab {
  color: #b75371;
  transform: scale(1.0);
  border-bottom: 2px solid #b75371;
}

</style>
